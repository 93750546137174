<template>
  <li
    class="im-carousel-item"
    :class="{ 'im-carousel-item-active': value == num }"
  >
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "ImCarouselItem",
  props: {
    num: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: Number,
      default: () => 0,
    },
  },
};
</script>
