<template>
  <div class="im-carousel-indicator">
    <ul>
      <li
        v-for="item in pages"
        :key="'indicator' + item"
        :class="{ active: value + 1 == item }"
        @click="change(item)"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CarouselIndicator",
  props: {
    pages: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.page = this.value;
  },
  methods: {
    change(page) {
      this.page = page;
      this.$emit("change", page - 1);
    },
  },
};
</script>
