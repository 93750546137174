<template>
  <div class="container mt-20 mb-20 pb-20">
    <div>
      <router-link to="/">
        <img src="../../assets/images/logo.png" width="200px"/>
      </router-link>
    </div>
    <el-card
      class="mt-20"
      shadow="hover"
      :body-style="{ padding: '20px' }"
      v-loading="loading"
    >
      <div class="d-flex justify-content-center flex-column" slot="header">
        <h1 class="text-black">{{ detail.title }}</h1>
        <div class="text-gray-6">
          {{ detail.createTime }}
          <span class="ml-10">
            {{ $utils.contrastTime(detail.create_time) }}</span
          >
          <span class="ml-10" v-if="detail.category">
            {{ detail.category.name }}</span
          >
        </div>
      </div>
      <div
        class="content-carousel"
        v-if="detail && detail.detail && detail.detail.imagesData.length > 0"
      >
        <ImCarousel>
          <ImCarouselItem
            v-for="(item, i) in detail.detail.imagesData"
            :key="'ImCarousel' + i"
          >
            <a :href="viewImg(item.path)" target="_blank">
              <img :src="viewImg(item.path)" alt="" />
            </a>
          </ImCarouselItem>
        </ImCarousel>
      </div>
      <div
        class="content"
        v-if="detail && detail.detail"
        v-html="detail.detail.content"
      ></div>
    </el-card>
  </div>
</template>

<script>
import { ImCarousel, ImCarouselItem } from "@/components/carousel";
export default {
  name: "List",
  components: { ImCarousel, ImCarouselItem },
  data() {
    return {
      loading: false,
      id: 0,
      detail: {},
      metaTitle: "",
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.id = this.$route.params.id;

        this.getDetail();
      },
      deep: true,
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  metaInfo() {
    return {
      title: this.metaTitle + "-帮助-" + this.Settings.base.name,
    };
  },
  mounted() {},
  methods: {
    getDetail() {
      this.loading = true;
      this.$api.help.Help.detail({ id: this.id })
        .then((res) => {
          this.loading = false;
          this.detail = res.data;
          this.metaTitle =
            this.detail.title +
            (this.detail.category ? "-" + this.detail.category.name : "");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>