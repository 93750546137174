/*
 * @Description:
 * @CopyRight: Tapp Commerce
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-04-11 09:42:03
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-05-16 10:22:52
 * @FilePath: /website/src/components/carousel/index.js
 */
import ImCarousel from "./src/Carousel.vue";
import ImCarouselItem from "./src/CarouselItem.vue";
ImCarousel.install = function (Vue) {
  Vue.component("ImCarouselItem", ImCarouselItem);
  Vue.component("ImCarousel", ImCarousel);
};
export default ImCarousel;

export { ImCarousel, ImCarouselItem };
