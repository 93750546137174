<template>
  <div class="im-carousel-button">
    <button type="button" @click="change(-1)">
      <svg
        t="1621229465403"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1177"
        width="16"
        height="16"
      >
        <path
          d="M677.888 26.396l-431.56 431.56c-1.023 0.91-2.047 1.934-3.071 2.844-28.445 28.444-28.445 74.41 0 102.855L677.092 997.49c28.444 28.444 74.41 28.444 102.855 0s28.444-74.41 0-102.855L397.653 512.34l383.09-383.09c28.445-28.444 28.445-74.41 0-102.855-28.444-28.33-74.41-28.33-102.855 0z"
          p-id="1178"
          fill="#ffffff"
        ></path>
      </svg>
    </button>
    <button type="button" @click="change(1)">
      <svg
        t="1621229502751"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="999"
        width="16"
        height="16"
      >
        <path
          d="M346.112 997.604l431.56-431.56c1.023-0.91 2.047-1.934 3.071-2.844 28.445-28.444 28.445-74.41 0-102.855L346.908 26.396c-28.444-28.444-74.41-28.444-102.855 0s-28.444 74.411 0 102.856l382.294 382.293-383.09 383.203c-28.445 28.445-28.445 74.411 0 102.856 28.444 28.33 74.41 28.33 102.855 0z"
          p-id="1000"
          fill="#ffffff"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "CarouselButtonr",
  props: {
    pages: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.page = this.value;
  },
  methods: {
    change(page) {
      this.page += page;
      if (this.page < 0) {
        this.page = this.pages - 1;
      }
      if (this.page >= this.pages) {
        this.page = 0;
      }

      this.$emit("change", this.page);
    },
  },
};
</script>
