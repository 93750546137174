<template>
  <div ref="carousel" class="im-carousel">
    <div class="im-carousel-scroll">
      <ul
        ref="carouselList"
        class="im-carousel-list"
        :style="{
          transform: 'translateX(' + postion + ')',
          transitionDuration: duration + 's',
        }"
      >
        <slot></slot>
      </ul>
    </div>
    <CarouselIndicator
      v-if="indicator"
      v-model="page"
      :pages="pages"
      @change="pageChange"
    />
    <CarouselButton
      v-if="button"
      v-model="page"
      :pages="pages"
      @change="pageChange"
    />
  </div>
</template>

<script>
import "./style.scss";
import CarouselIndicator from "./CarouselIndicator";
import CarouselButton from "./CarouselButton";
export default {
  name: "ImCarousel",
  components: {
    CarouselIndicator,
    CarouselButton,
  },
  props: {
    delay: {
      type: Number,
      default: () => 5000,
    },
    autoPlay: {
      type: Boolean,
      default: () => true,
    },
    indicator: {
      type: Boolean,
      default: () => true,
    },
    button: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      currentType: 0,
      casousel: null,
      carouselList: null,
      timer: null,
      pages: 1,
      page: 0,
      duration: 0,
      postion: "0px",
      dragStartX: 0,
      defaultOptions: {
        id: "tree_" + Math.floor(Math.random() * 100000),
        label: "label",
        multiple: true,
        checkbox: true,
      },
      termOptions: {},
    };
  },
  watch: {
    options: {
      handler: function (val) {
        this.initOptions();
      },
      deep: true,
    },
    value(val) {
      this.currentType = val;
    },
    currentType(currentType) {
      this.$emit("input", currentType);
    },
  },
  mounted() {
    this.duration = this.delay / 1000 / 4;
    this.carouselList = this.$refs.carouselList;
    this.casousel = this.$refs.carousel;
    this.initPlay();
    // this.$refs.carouselList.addEventListener("touchstart", this.touchstart);
    this.casousel.addEventListener("touchend", this.touchEnd);
    this.casousel.addEventListener("mouseup", this.touchEnd);
    this.casousel.addEventListener("mousedown", this.touchStart);
    this.casousel.addEventListener("mouseover", this.stop);
    this.casousel.addEventListener("mouseleave", this.play);
  },

  methods: {
    initPlay() {
      if (this.carouselList.scrollWidth > this.casousel.clientWidth) {
        this.pages = Math.ceil(
          this.carouselList.scrollWidth / this.casousel.clientWidth
        );

        this.play();
      }
    },
    play() {
      if (!this.autoPlay) {
        return false;
      }
      this.timer = setInterval(() => {
        this.page++;
        if (this.page >= this.pages) {
          this.page = 0;
        }
        this.slick(this.page);
      }, this.delay);
    },
    stop() {
      clearInterval(this.timer);
    },
    slick(index) {
      const postion = this.casousel.clientWidth * index;
      this.postion = "-" + postion + "px";
    },
    touchStart(e) {
      this.dragStartX =
        e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientX
          : e.clientX;
    },
    touchEnd(e) {
      const x =
        (e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientX
          : e.clientX) - this.dragStartX;

      if (Math.abs(x) < 100) {
        return;
      }
      if (x < 0) {
        this.page++;
        if (this.page >= this.pages) {
          this.page = 0;
        }
      } else {
        this.page--;
        if (this.page < 0) {
          this.page = this.pages - 1;
        }
      }

      this.slick(this.page);
    },
    pageChange(page) {
      this.page = page;
      this.slick(this.page);
    },
  },
};
</script>
